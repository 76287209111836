@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: "Poppins";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
